.aside {
    width: 160px;
    height: 100%;
    position: fixed;
    background: var(--sideBar-bg);
    top: 0;
    left: 0;
    z-index: 3;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate(0);
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 0px;
      top: 0;
      left: 0;
      background-color: var(--typical-color);
    }
  }
  // show sidebar when you click on the icon menu
  .show-asideBar {
    transform: translate(-100%);
  }
  img {
    width: 100%;
  }
  
  // logo section in the sidebar
  .logo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    span {
      color: #fff;
    }
  }
  .sidebar__logo {
    width: 60px;
    height: auto;
    fill: #06b8cc;
  }
  // the classname that wraps all the element in the sidebar
  .aside-wrapper {
    width: 80%;
    min-height: 90vh;
    overflow-y: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  // toggle btn
  .menu-icon,
  .close-btn {
    position: fixed;
    right: 40px;
    top: 40px;
    color: var(--primary-color);
    font-size: 1.8rem;
    cursor: pointer;
    display: none;
    transition: all 0.2s linear;
    z-index: 2;
  }
  .menu-icon:hover {
    transform: translate(5px);
  }
  .close-btn {
    z-index: 999;
    position: absolute;
  }
  .close-btn:hover {
    transform: rotate(360deg);
  }
  
  // sidebar list element starts here
  
  ul.side-link li {
    line-height: 2.8;
    position: relative;
  }
  .nav__links {
    display: block;
    transition: all 250ms linear;
  }
  .active-links {
    color: var(--color-tem);
  }
  
  ul.side-link li .link-icon {
    font-size: 15px;
    margin-right: 15px;
    color: var(--color-tem);
  }
  
  // social media icons
  .social-icon {
    right: 100px;
    bottom: 0;
  }
  .social-icon .icon {
    font-size: 15px;
    margin-right: 15px;
    transition: 0.2s linear;
    color: var(--color-tem);
    &:hover {
      color: var(--anchor-color);
    }
  }
  
  // for bigger screens
  
  @media screen and (max-width: 1200px) {
    .aside {
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: all 350ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .open-sidebar {
      height: 100%;
      overflow: visible;
    }
    .menu-icon,
    .close-btn {
      display: block;
    }
    .social-icon {
      text-align: center;
    }
  }
  
  // small devices
  @media screen and (max-width: 1024px) {
    .aside-wrapper {
      text-align: center;
    }
    ul.side-link li {
      line-height: 2.8;
      font-weight: bold;
    }
    ul.side-link li .link-icon {
      display: none;
    }
  
    // social media icons
    .social-icon {
      text-align: center;
    }
    .social-icon .icon {
      font-size: 25px;
      &:hover {
        animation: rubberBand 1s;
      }
    }
  }