// theme icon wrapper settings
.theme-icon-wrapper {
    position: fixed;
    right: 40px;
    bottom: 30px;
    cursor: pointer;
    background: var(--primary-bg);
    padding: 10px;
    border-radius: 50% 50% 0;
    box-shadow: var(--box-shadow);
    z-index: 999;
  }
  
  // theme icon
  .theme-icon {
    font-size: 35px;
    color: var(--color-tem);
    transition: all 0.5s linear;
    animation: theme 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2s infinite
      alternate-reverse;
  }
  @keyframes theme {
    0% {
      transform: rotate(0deg) scale(0.7);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  // switch theme wrapper
  .theme-wrapper {
    width: auto;
    height: auto;
    position: fixed;
    bottom: 230px;
    right: 0px;
    background: var(--theme-wrapper-bg);
    border-radius: 50px 0 0 20px;
    box-shadow: var(--box-shadow);
    transition: all 350ms cubic-bezier(0, 0.54, 1, 0.13);
    padding: 30px;
    transform: translateX(110%);
    display: flex;
    flex-direction: column;
    z-index: 998;
    .theme-header {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
    }
  }
  // show template properties onClick()
  .show-template {
    transform: translateX(0%);
  }
  .theme-wrapper span {
    font-size: 1rem;
    font-weight: 500;
    color: var(--primary-color);
  }
  // close template onClick() properties
  .close-template {
    font-size: 20px;
    color: var(--light-bg);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  .close-template:hover {
    transform: rotate(360deg);
  }
  
  // theme mode section
  .mode {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      width: 60px;
      height: 60px;
      font-size: 1.5rem;
      display: grid;
      place-items: center;
      border-radius: 10px;
      cursor: pointer;
      color: #06b8cc;
      transition: 250ms linear;
      box-shadow: var(--box-shadow);
    }
    .icon {
      transition: all 0.2s cubic-bezier(0.8, 0.84, 0.44, 1);
      &:hover {
        transform: rotate(360deg);
      }
    }
  }
  .mode div:last-child {
    margin-left: 20px;
  }
  .template-wrapper {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  // theme template
  .template {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--primary-bg);
    cursor: pointer;
    transition: 0.3s linear;
    box-shadow: var(--box-shadow);
    display: grid;
    place-items: center;
    div {
      width: 25px;
      height: 25px;
      border-radius: 50% 0;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        animation: rubberBand 1s;
      }
    }
  }
  
  // theme template colors
  
  .template-1 {
    background-color: rgb(224, 134, 0);
  }
  .template-2 {
    background-color: rgb(16, 134, 67);
  }
  .template-3 {
    background-color: rgb(179, 65, 211);
  }
  .template-4 {
    background-color: rgb(210, 201, 199);
  }
  .template-5 {
    background-color: #a90303;
  }
  .template-6 {
    background: linear-gradient(to right, #06b8cc, #2d2b2b);
  }
  
  .template-7 {
    background: #a37346;
  }
  
  @media screen and (max-width: 450px) {
    .theme-wrapper {
      padding: 15px;
    }
    .theme-icon-wrapper {
      padding: 7px;
    }
    .theme-icon {
      font-size: 28px;
    }
  }